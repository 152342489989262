import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { DEFAULT_FORMAT, DEFAULT_TIMEZONE } from '../constants/dateTime'

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatDateTime = (dateTime: string, format: string = DEFAULT_FORMAT, timezone = DEFAULT_TIMEZONE) => {
    if (!dateTime) {
        return dateTime
    }

    return dayjs.utc(dateTime).tz(timezone).format(format)
}
