import { FilterValue, SorterResult } from 'antd/es/table/interface'
import { Url } from 'next/dist/shared/lib/router/router'
import { ReadonlyURLSearchParams } from 'next/navigation'
import { NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

const FILTER_SEPARATOR = ','

const getFilterFromQueryParamByColumnKey = (key: string, searchParams: ReadonlyURLSearchParams): string[] | null => {
    const values = searchParams.get(key)
    if (!values) {
        return null
    }

    return values?.split(FILTER_SEPARATOR)
}

const getSortFromQueryParamByColumnKey = (
    key: string,
    searchParams: ReadonlyURLSearchParams,
    defaultOrder?: 'ascend' | 'descend'
): 'ascend' | 'descend' | null => {
    const sort = searchParams.get('sort')
    if (!sort) {
        return defaultOrder ?? null
    }

    const [columnKey, order] = sort.split(FILTER_SEPARATOR)
    if (key !== columnKey) {
        return null
    }

    return order as 'ascend' | 'descend'
}

const updateUrlWithFilterAndSortQueryParams = (
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    router: NextRouter
) => {
    const { push, pathname, query } = router

    const url: Url = {
        pathname,
        query: {
            ...getFiltersQueryParams(filters, query),
            ...getSortQueryParams(sorter),
        },
    }

    push(url, undefined, {
        shallow: true,
    })
}

const getFiltersQueryParams = (
    filters: Record<string, FilterValue | null>,
    query: ParsedUrlQuery
): Record<string, string> => {
    const searchQuery = query

    const queryParams = Object.assign(
        {},
        ...Object.keys(filters).map((key) => {
            const filter = filters[key]

            if (filter === null) {
                if (searchQuery[key]) {
                    delete searchQuery[key]
                }
                return
            }

            const filterValues = Array.from(filter.values())
            if (!filterValues || filterValues.every((v) => v === '')) {
                return
            }

            return { [key]: filterValues.join(FILTER_SEPARATOR) }
        })
    )

    return {
        ...searchQuery,
        ...queryParams,
    }
}

const getSortQueryParams = (sorter: SorterResult<any> | SorterResult<any>[]): Record<string, string> | null => {
    const sortedColumn = sorter as SorterResult<any>
    if (!sortedColumn) {
        return null
    }

    return {
        sort: `${sortedColumn.columnKey}${FILTER_SEPARATOR}${sortedColumn.order}`,
    }
}

export const tableFilteringQueryStringHelper = {
    updateUrlWithFilterAndSortQueryParams,
    getFilterFromQueryParamByColumnKey,
    getSortFromQueryParamByColumnKey,
}
