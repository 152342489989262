import { docTemplateClient } from 'src/axios/apiClients'
import { ApiService } from 'src/axios/apiService'
import { IContract } from 'src/models/contract'
import { IContractDetails } from 'src/models/contractDetails'
import { IFileContentResponse } from 'src/models/fileContentResponse'
import CreationResponse from 'src/types/common/CreationResponse'
import HistoryRecord from 'src/types/common/HistoryRecord'
import UpdateOperationResponse from 'src/types/common/UpdateOperationResponse'
import { ContractType } from 'src/types/enums/ContractType'

const CONTRACT_API_PATH = '/contracts'
const DOWNLOAD_PATH = 'download'

const docTemplateApiClient = (accessToken: string) => {
    return new ApiService(docTemplateClient(accessToken))
}

export type contractDetailsType = {
    name: string
    spvId: string
    type: ContractType
    language: string
    isActive: boolean
    productIds: string[]
}
export type contractTemplates = {
    templatesWithTemplateOrder: {
        [templateId: string]: number
    }
}

type ContractGroupExportInputData = {
    contractGroupIds: string[]
}

export type ContractInputData = contractDetailsType & contractTemplates & { id?: string }

export const getAllContracts = async (accessToken: string) => {
    return await docTemplateApiClient(accessToken).get<IContract[]>(CONTRACT_API_PATH)
}

export const getContractHistoryById = async (contractId: string, accessToken: string) => {
    const response = await docTemplateApiClient(accessToken).get<HistoryRecord[]>(
        `${CONTRACT_API_PATH}/${contractId}/history`
    )
    return response.data
}

export const getContractById = async (contractId: string, accessToken: string) =>
    await docTemplateApiClient(accessToken).get<IContractDetails>(`${CONTRACT_API_PATH}/${contractId}`)

export const downloadContract = async (contractId: string, accessToken: string) =>
    await docTemplateApiClient(accessToken).get<IFileContentResponse>(
        `${CONTRACT_API_PATH}/${DOWNLOAD_PATH}/${contractId}`
    )

export const deleteContract = async (contractId: string, accessToken: string) => {
    await docTemplateApiClient(accessToken).delete(`${CONTRACT_API_PATH}/${contractId}`)
}
export const createContract = async (contractDetails: ContractInputData, accessToken: string) => {
    const response = await docTemplateApiClient(accessToken).post<ContractInputData, CreationResponse>(
        `${CONTRACT_API_PATH}`,
        contractDetails,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return response.data
}

export const updateContract = async (contractDetails: ContractInputData, accessToken: string) => {
    const response = await docTemplateApiClient(accessToken).update<ContractInputData, UpdateOperationResponse>(
        `${CONTRACT_API_PATH}`,
        contractDetails,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }
    )
    return response.data
}

export const exportContracts = async (contractGroupIds: string[], accessToken: string): Promise<Blob> => {
    const response = await docTemplateApiClient(accessToken).post<ContractGroupExportInputData, Blob>(
        `${CONTRACT_API_PATH}/export`,
        { contractGroupIds },
        {
            headers: {
                'Content-Type': 'application/json',
            },
            responseType: 'blob',
        }
    )
    return response.data
}
