import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

export class ApiService {
    private axiosInstance: AxiosInstance

    constructor(apiClient: AxiosInstance) {
        this.axiosInstance = apiClient
    }

    // Create (POST)
    public async post<TRequest, TResponse>(
        url: string,
        data: TRequest,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<TResponse>> {
        try {
            return await this.axiosInstance.post<TResponse>(url, data, config)
        } catch (error) {
            this.handleError(error)
            throw error
        }
    }

    // Read (GET)
    public async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            return await this.axiosInstance.get(url, config)
        } catch (error) {
            this.handleError(error)
            throw error
        }
    }

    // Update (PUT)
    public async update<TRequest, TResponse>(
        url: string,
        data: TRequest,
        config?: AxiosRequestConfig
    ): Promise<AxiosResponse<TResponse>> {
        try {
            return await this.axiosInstance.put<TResponse>(url, data, config)
        } catch (error) {
            this.handleError(error)
            throw error
        }
    }

    // Delete (DELETE)
    public async delete<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        try {
            return await this.axiosInstance.delete(url, config)
        } catch (error) {
            this.handleError(error)
            throw error
        }
    }

    // Optional method to handle errors in a centralized place
    private handleError(error: any): void {
        console.error('API Error:', error.message || error)

        if (error.response?.status === 404) {
            throw new Error('Resource not found')
        }

        if (error.response?.status === 500) {
            throw new Error('Internal server error')
        }

        if (error.response?.status === 400) {
            throw new Error('Bad request')
        }
    }
}
