import { FloatButton, Table } from 'antd'
import { ColumnsType, TableProps } from 'antd/es/table'
import { TableRowSelection } from 'antd/es/table/interface'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import { tableFilteringQueryStringHelper } from 'src/helpers/tableFilteringQueryStringHelper'

interface ITableComponentProps {
    columns: ColumnsType<any>
    data: readonly any[]
    rowSelection?: TableRowSelection<any>
    rowClassName?: string | ((record: any, index: number, indent: number) => string)
    useUrlFiltering?: boolean
    scrollToTopButton?: boolean
    tableOffest?: number
}

function TableComponent({
    columns,
    data,
    rowSelection,
    rowClassName,
    useUrlFiltering = false,
    scrollToTopButton = true,
    tableOffest = 300, // Offset for header/footer
}: ITableComponentProps) {
    const [scrollHeight, setScrollHeight] = useState<number>(300)
    const [scrollPosition, setScrollPosition] = useState<number>(0)

    const tblRef: Parameters<typeof Table>[0]['ref'] = useRef(null)
    const router = useRouter()

    useEffect(() => {
        // Calculate the available height
        const calculateHeight = () => {
            const pageHeight = window.innerHeight
            setScrollHeight(pageHeight - tableOffest)
        }

        //on mount
        calculateHeight()

        // on browser window resized
        window.addEventListener('resize', calculateHeight)

        // Cleanup event listener - unmount
        return () => {
            window.removeEventListener('resize', calculateHeight)
        }
    }, [])

    const tableBody = typeof document !== 'undefined' ? document.querySelector('.ant-table-body') : null
    const onscroll = () => {
        setScrollPosition(tableBody?.scrollTop ?? 0)
    }

    const onChange: TableProps<any>['onChange'] = (pagination, filters, sorter, extra) => {
        if (!useUrlFiltering) {
            return
        }

        tableFilteringQueryStringHelper.updateUrlWithFilterAndSortQueryParams(filters, sorter, router)
    }

    return (
        <div>
            <Table
                ref={tblRef}
                rowClassName={rowClassName}
                columns={columns}
                dataSource={data}
                rowSelection={rowSelection}
                pagination={false}
                scroll={{ y: scrollHeight, scrollToFirstRowOnChange: true }}
                data-testid="tblDataGrid"
                onChange={onChange}
                onScroll={onscroll}
            />
            {scrollToTopButton && (
                <FloatButton.BackTop
                    target={() => tableBody as HTMLElement}
                    visibilityHeight={80 - scrollPosition}
                    type={'primary'}
                    style={{ insetInlineEnd: '10%' }}
                    tooltip="Scroll table to top"
                    onClick={() => {
                        tblRef.current?.scrollTo({ index: 0 })
                    }}
                />
            )}
        </div>
    )
}

export default TableComponent
