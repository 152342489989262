import { SpacerProps } from './spacer.types'

const Spacer = ({ size = 12 }: Partial<SpacerProps>) => {
    return (
        <div
            style={{
                display: 'block',
                width: '100%',
                paddingBottom: `${size}px`,
            }}
        ></div>
    )
}

export default Spacer
