import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, InputRef, Row, Space, Typography } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ColumnType, FilterConfirmProps, TableRowSelection } from 'antd/es/table/interface'
import { GetServerSidePropsContext } from 'next'
import { getServerSession } from 'next-auth'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { authOptions } from 'pages/api/auth/[...nextauth]'
import { useEffect, useRef, useState } from 'react'
import TableComponent from 'src/components/common/TableComponent'
import Container from 'src/components/layout/Container/Container'
import Spacer from 'src/components/layout/Spacer/Spacer'
import { appRoutes } from 'src/constants/appRoutes'
import { formatDateTime } from 'src/helpers/dateTimeHelper'
import { tableFilteringQueryStringHelper } from 'src/helpers/tableFilteringQueryStringHelper'
import { IContract } from 'src/models/contract'
import { exportContracts, getAllContracts } from 'src/services/contractService'
import { ContractType2LabelMapping } from 'src/types/enums/ContractType'
import { Status } from 'src/types/enums/Status'
import styles from './index.module.css'

const { Title } = Typography

enum ColumnKeys {
    Name = 'name',
    ContractType = 'contractType',
    SpvName = 'spvName',
    Language = 'language',
    Status = 'status',
    LastModifiedDate = 'lastModifiedDate',
}

interface IContractData extends IContract {
    key: string
}

interface IContractListProps {
    data: IContract[]
}

type DataIndex = keyof IContractData

export default function Contracts({ data }: IContractListProps) {
    const [rows, setRows] = useState<IContractData[]>([])
    const { push } = useRouter()
    const searchParams = useSearchParams()
    const { data: session } = useSession()
    const accessToken = session ? session?.user.accessToken : ''

    const searchInput = useRef<InputRef>(null)
    const [selectedRows, setSelectedRows] = useState<string[]>([])

    const handleSearch = (confirm: (param?: FilterConfirmProps) => void, close: () => void) => {
        confirm({ closeDropdown: false })
        close()
    }

    const handleReset = (
        clearFilters: () => void,
        confirm: (param?: FilterConfirmProps) => void,
        close: () => void
    ) => {
        clearFilters()
        confirm({ closeDropdown: false })
        close()
    }

    const rowSelection: TableRowSelection<IContractData> = {
        onChange: (selectedRowKeys) => setSelectedRows(selectedRowKeys as string[]),
        type: 'checkbox',
    }

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IContractData> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }}
                    onPressEnter={() => handleSearch(confirm, close)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(confirm, close)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters, confirm, close)}
                        type="primary"
                        ghost
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close()
                        }}
                    >
                        Close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: <SearchOutlined />,
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100)
            }
        },
    })

    useEffect(() => {
        const rows = (data || []).map((x) => {
            return {
                ...x,
                key: x.id,
            }
        })
        setRows(rows)
    }, [data])

    const columns: ColumnsType<IContractData> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: ColumnKeys.Name,
            defaultFilteredValue:
                tableFilteringQueryStringHelper.getFilterFromQueryParamByColumnKey(ColumnKeys.Name, searchParams) ?? [],
            ...getColumnSearchProps(ColumnKeys.Name),
            onFilter: (value, record) =>
                record[ColumnKeys.Name]
                    .toString()
                    .toLowerCase()
                    .includes((value as string).toLowerCase()),
            sorter: (a, b) => a.name.localeCompare(b.name),
            defaultSortOrder: tableFilteringQueryStringHelper.getSortFromQueryParamByColumnKey(
                ColumnKeys.Name,
                searchParams,
                'ascend'
            ),
            render: (_, value) => (
                <Link href={`contracts/${value.id}`} style={{ color: 'black' }}>
                    {value.name}
                </Link>
            ),
        },
        {
            title: 'Contract Type',
            dataIndex: 'contractType',
            key: ColumnKeys.ContractType,
            defaultFilteredValue:
                tableFilteringQueryStringHelper.getFilterFromQueryParamByColumnKey(
                    ColumnKeys.ContractType,
                    searchParams
                ) ?? [],
            defaultSortOrder: tableFilteringQueryStringHelper.getSortFromQueryParamByColumnKey(
                ColumnKeys.ContractType,
                searchParams
            ),
            filters: [...new Set(rows.map((contract) => contract.contractType))].map((type) => {
                return {
                    text: type,
                    value: type,
                }
            }),
            onFilter: (value, record) => record.contractType.indexOf(value as string) === 0,
            sorter: (a, b) => a.contractType.localeCompare(b.contractType),
            render: (_, value) => {
                if (value.contractType === 'BuyInLease') {
                    return 'Buy-in-Lease'
                }

                return (ContractType2LabelMapping as never)[value.contractType]
            },
        },
        {
            title: 'Spv Name',
            dataIndex: 'spvName',
            key: ColumnKeys.SpvName,
            defaultFilteredValue:
                tableFilteringQueryStringHelper.getFilterFromQueryParamByColumnKey(ColumnKeys.SpvName, searchParams) ??
                [],
            defaultSortOrder: tableFilteringQueryStringHelper.getSortFromQueryParamByColumnKey(
                ColumnKeys.SpvName,
                searchParams
            ),
            filters: [...new Set(rows.map((contract) => contract.spvName))].map((spv) => {
                return {
                    text: spv,
                    value: spv,
                }
            }),
            onFilter: (value, record) => record.spvName.indexOf(value as string) === 0,
            sorter: (a, b) => a.spvName.localeCompare(b.spvName),
        },
        {
            title: 'Language',
            dataIndex: 'language',
            key: ColumnKeys.Language,
            defaultFilteredValue:
                tableFilteringQueryStringHelper.getFilterFromQueryParamByColumnKey(ColumnKeys.Language, searchParams) ??
                [],
            defaultSortOrder: tableFilteringQueryStringHelper.getSortFromQueryParamByColumnKey(
                ColumnKeys.Language,
                searchParams
            ),
            filters: [...new Set(rows.map((contract) => contract.language))].map((language) => {
                return {
                    text: language,
                    value: language,
                }
            }),
            onFilter: (value, record) => record.language.indexOf(value as string) === 0,
            sorter: (a, b) => a.language.localeCompare(b.language),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: ColumnKeys.Status,
            defaultFilteredValue: tableFilteringQueryStringHelper.getFilterFromQueryParamByColumnKey(
                ColumnKeys.Status,
                searchParams
            ) ?? [Status.Active],
            defaultSortOrder: tableFilteringQueryStringHelper.getSortFromQueryParamByColumnKey(
                ColumnKeys.Status,
                searchParams
            ),
            filters: [...new Set(rows.map((contract) => contract.status))].map((status) => {
                return {
                    text: status,
                    value: status,
                }
            }),
            filterResetToDefaultFilteredValue: true,
            onFilter: (value, record) => record.status.indexOf(value as string) === 0,
            sorter: (a, b) => a.status.localeCompare(b.status),
            render: (_, value) => (
                <span className={styles.status} data-status={value.status}>
                    {value.status}
                </span>
            ),
        },
        {
            title: 'Last Modified',
            dataIndex: 'lastModifiedDate',
            key: ColumnKeys.LastModifiedDate,
            defaultSortOrder: tableFilteringQueryStringHelper.getSortFromQueryParamByColumnKey(
                ColumnKeys.LastModifiedDate,
                searchParams
            ),
            sorter: (a, b) =>
                new Date(a.lastModifiedDate == null ? a.createdDate : a.lastModifiedDate).getTime() -
                new Date(b.lastModifiedDate == null ? b.createdDate : b.lastModifiedDate).getTime(),
            render: (_, value) =>
                formatDateTime(value.lastModifiedDate == null ? value.createdDate : value.lastModifiedDate),
        },
    ]

    const handleCreateContractButton = () => push(appRoutes.handleContract.path)
    const handleExportButton = async () => {
        const exportData = await exportContracts(selectedRows, accessToken)
        const exportUrl = window.URL.createObjectURL(exportData)

        //downloading
        const tempLink = document.createElement('a')
        tempLink.href = exportUrl
        tempLink.setAttribute('download', 'contractGroupExport.json')
        tempLink.click()
        tempLink.remove()
    }

    return session ? (
        <Container>
            <Spacer size={32} />
            <Row justify={'end'}>
                <Col flex={'auto'}>
                    <Button
                        className="add-btn-margin"
                        onClick={handleExportButton}
                        type={'primary'}
                        disabled={selectedRows.length == 0}
                    >
                        Export
                    </Button>
                </Col>
                <Col>
                    <Button
                        className="add-btn-margin"
                        onClick={handleCreateContractButton}
                        type={'primary'}
                        icon={<PlusOutlined />}
                    >
                        New Contract Group
                    </Button>
                </Col>
            </Row>

            <TableComponent columns={columns} data={rows} rowSelection={rowSelection} useUrlFiltering></TableComponent>
        </Container>
    ) : (
        <></>
    )
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
    if (context != undefined) {
        const value = await getServerSession(context.req, context.res, authOptions)
        if (value) {
            const accessToken = value.user.accessToken
            const { data } = await getAllContracts(accessToken)
            return { props: { data } }
        }
    }
    return { props: {} }
}
