import axios, { AxiosInstance } from 'axios'
import config from '../config/config'

const createClient = (
    baseURL: string,
    subscriptionKey: string,
    subscriptionValue: string,
    accessToken?: string
): AxiosInstance => {
    return axios.create({
        baseURL,
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
            [subscriptionKey]: subscriptionValue,
        },
    })
}

export const anonymousClient = (): AxiosInstance => {
    const apiClient = axios.create({
        baseURL: config.DOCTEMPLATE_API_URL,
        headers: {
            'Content-Type': 'application/json',
            [config.DOCTEMPLATE_API_SUBSCRIPTION_KEY]: config.DOCTEMPLATE_API_SUBSCRIPTION_VALUE,
        },
    })
    return apiClient
}

export const docTemplateClient = (accessToken?: string): AxiosInstance =>
    createClient(
        config.DOCTEMPLATE_API_URL,
        config.DOCTEMPLATE_API_SUBSCRIPTION_KEY,
        config.DOCTEMPLATE_API_SUBSCRIPTION_VALUE,
        accessToken
    )

export const docVariableClient = (accessToken?: string): AxiosInstance =>
    createClient(
        config.DOCVARIABLE_API_URL,
        config.DOCVARIABLE_API_SUBSCRIPTION_KEY,
        config.DOCVARIABLE_API_SUBSCRIPTION_VALUE,
        accessToken
    )
